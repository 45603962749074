import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import './reroll.scss';
import '../../generic-page.scss';
import { Row, Table, Accordion, Card, Col } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const RevGuidesBeginner: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide r99-reroll'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_beginner.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Beginner Guide</h1>
          <h2>
            A beginner guide to Reverse: 1999 that will help you start strong in
            the game.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Foreword" />
              <p>
                Special thanks to <strong>Liriell</strong> who worked tirelessly
                on this guide.
              </p>
              <SectionHeader title="Introduction" />
              <p>
                This guide is aimed at giving tips for those who just started
                playing Reverse:1999 or plan to. If you're one of them, welcome!
                But if you don't know what the game is about yet or where to
                start, you may want to check our [Introduction] guide first.
                Reroll is not needed or recommended in this game, but if you'd
                like to check that anyway, we also have a [Reroll] guide.
                Without further ado, let's get started!
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Introduction to the game"
                  link="/re1999/guides/introduction-to-the-game"
                  image={
                    <StaticImage
                      src="../../../images/reverse/categories/category_intro.png"
                      alt="Introduction to the game"
                    />
                  }
                />
                <CategoryCard
                  title="Reroll guide"
                  link="/re1999/guides/reroll"
                  image={
                    <StaticImage
                      src="../../../images/reverse/categories/category_reroll.webp"
                      alt="Reroll guide"
                    />
                  }
                />
              </Row>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Common Terms" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_1.webp"
                alt="Guides"
              />
              <p>
                These are some common terms you may find around the game and
                community:
              </p>
              <ul>
                <li>
                  <strong>AP</strong>: Action Points. Each round, there's a
                  limited amount of Action Points for using or moving cards. The
                  amount of AP is tied to the amount of characters you have on
                  the field, which means that on most occasions, you'll have 3
                  AP per round. Having two characters on the field means only 2
                  AP per round, and having only one character means only 1 AP
                  per round, so be extra careful about not letting your
                  characters die! There are special cases such as boss battles
                  and duel stages, but the general rule is that the amount of AP
                  equals the amount of characters in battle. Tuning Skills don't
                  consume AP.
                </li>
                <ul>
                  <li>
                    “AP generous”: Refers to characters who don't need to spend
                    many Action Points to fulfill their role.
                  </li>
                  <li>
                    “AP greedy”: The opposite. Characters who need to use cards
                    and moves constantly to fulfill their role.
                  </li>
                </ul>
                <li>
                  <strong>Tuning Skills</strong>: Vertin's skills. They can be
                  found in the bottom left corner of the screen during combat.
                  There are two sets: "First Melody" and "Grand Orchestra".
                </li>
              </ul>
              <StaticImage
                src="../../../images/reverse/beginner/tuning_1.webp"
                alt="Guides"
              />
              <br />
              <StaticImage
                src="../../../images/reverse/beginner/tuning_2.webp"
                alt="Guides"
              />
              <ul>
                <li>
                  <strong>Moxie</strong>: The little dots below the HP bar of
                  each character. It's possible to gain Moxie by using cards,
                  merging them or moving them. Some characters also have their
                  own mechanics to gain Moxie or give Moxie to allies.
                </li>
                <li>
                  <strong>i0, i1, i2, i3</strong>: Refers to the character's
                  Insight level, also known as “Inheritance”. Similar to
                  ascension or promotion in other games. Low rarity characters
                  only go up to i2 (insight 2) while 5☆ and 6☆ can reach i3
                  (insight 3). Each Insight level unlocks a passive.
                </li>
              </ul>
              <StaticImage
                src="../../../images/reverse/beginner/inher.webp"
                alt="Guides"
              />
              <ul>
                <li>
                  <strong>Afflatus</strong>: The name given to the "Elements" in
                  Reverse:1999. There are six of them: Star, Mineral, Beast,
                  Plant, Spirit and Intellect.
                </li>
                <li>
                  <strong>Reality DMG and Mental DMG</strong>: Similar to
                  "Physical" and "Magical" damage from other games. Reality and
                  Mental damage have their Reality and Mental defense
                  counterparts. Meanwhile, "Genesis damage" ignores all kinds of
                  defense, being the equivalent to "True damage".
                </li>
                <li>
                  <strong>Strat / Team Comp</strong>: The kind of strategy or
                  team composition that the player is going for. Synergy and
                  team composition are key in Reverse:1999.
                </li>
                <li>
                  <strong>Substitute</strong>: In most stages, you can select up
                  to four characters, but while three of them will be on the
                  field, the fourth one will wait at the suitcase, with a
                  "substitute" tag (and a cute little alternate form known as
                  Udimo, being most often an animal that represents the
                  character). Once someone dies in combat, and only through
                  that, the Substitute will take their place.
                </li>
                <li>
                  <strong>Sacc / Sacrifice Strat</strong>: A kind of strategy
                  where the player purposefully sacrifices a character. The two
                  2☆, Door and Ms. Radio, have their kits made for this type of
                  strategy.
                </li>
                <li>
                  <strong>Psychube</strong>: The equivalent of gear or equipment
                  in this game. There are no banners for them, and they can only
                  be obtained by playing.
                </li>
                <li>
                  <strong>Resonance</strong>: Another facet of character
                  upgrade, Resonance allows for increasing raw attributes. When
                  saying that a character is at "i2 L50 R7", that means the
                  character is at "Insight 2, Level 50, Resonance level 7".
                  Resonance matters as much as the character's core level, and
                  sometimes more, so pay attention to it if you notice that your
                  characters are struggling.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="First steps" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_2.webp"
                alt="Guides"
              />
              <h5>#1 Enjoy the story</h5>
              <h5>List of which stages unlock what:</h5>
              <ul>
                <li>Tutorial ends at 1-1, unlocks 2x speed in combat.</li>
                <li>
                  After stage 1-6, unlock Traces (and you can get Leilani by
                  interacting with a Trace in the map),
                </li>
                <li>Clearing 1-11 unlocks Dust Farming (exp mat.) stages.</li>
                <li>
                  After stage 1-12, get Eagle by interacting with a Trace in the
                  map,
                </li>
                <li>At 1-14, Unlock the Tuning Skill Set “First Melody”</li>
                <li>
                  Tuning Skill Set "Grand Orchestra" can be obtained by clearing
                  all training stages. Training stages can be accessed from any
                  main story map, by clicking at the notepad icon at the top
                  right of the screen.
                </li>
                <ul>
                  <li>
                    Check the video on Basics of Combat below the list for some
                    helpful tips.
                  </li>
                </ul>
                <li>Stage 2-6 Unlocks Hard Mode</li>
                <li>Stage 2-8 Unlocks Insight stages</li>
                <li>
                  Stage 2-10 Unlocks Psychubes and Pneuma Analysis (Psychubes
                  farm stages)
                </li>
                <li>
                  Stage 3-2 Unlocks Resonance system and Artificial Somnambulism
                  game mode.
                </li>
              </ul>
              <Row className="video-row">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="sg6makwZn0w" />
                </Col>
              </Row>
              <h5>Answers for the puzzles</h5>
              <p>
                We have hidden the answers to the puzzle in the section below to
                avoid spoiling those who want to have a go at them first.
              </p>
              <Accordion className="guide-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Chapter 1</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered responsive className="guide-table">
                      <thead>
                        <tr>
                          <th>Stage</th>
                          <th>Question/Info</th>
                          <th>Answer/Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="first">Stage 1-7</td>
                          <td>
                            Sotheby’s mansion has thousands of artifacts. What
                            is the thing with one horn hung high above her hall
                            that always gazes at people?
                          </td>
                          <td>Quiet Ravenheart</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 1-10</td>
                          <td>
                            Matilda's School Report: [...] So what is Matilda
                            Bouanich's score?
                          </td>
                          <td>86</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 1-12</td>
                          <td>Item needed: Truth Serum</td>
                          <td>From a Trace after clearing stage 1-11</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 1-15</td>
                          <td>How many older sisters does Schneider have?</td>
                          <td>Twelve / 12</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Chapter 2</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered responsive className="guide-table">
                      <thead>
                        <tr>
                          <th>Stage</th>
                          <th>Question/Info</th>
                          <th>Answer/Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="first">Stage 2-3</td>
                          <td>Item needed: Tiny Key</td>
                          <td>Obtained by clearing 2-15</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 2-4</td>
                          <td colSpan={2}>
                            <StaticImage
                              src="../../../images/reverse/beginner/puzzle_1.webp"
                              alt="Guides"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="first">Stage 2-6</td>
                          <td>Item needed: Garden Key</td>
                          <td>Obtainable by clearing 2-12</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Chapter 3</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered responsive className="guide-table">
                      <thead>
                        <tr>
                          <th>Stage</th>
                          <th>Question/Info</th>
                          <th>Answer/Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="first">Stage 3-5</td>
                          <td>Item needed: Tiny Acorn</td>
                          <td>Obtained by answering the puzzle at 3-15</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 3-7</td>
                          <td>
                            Where are the fish and chips? / Where is the peas
                            puree?
                          </td>
                          <td>Basket / Cabinet</td>
                        </tr>
                        <tr>
                          <td className="first">Stage 3-12</td>
                          <td colSpan={2}>
                            <StaticImage
                              src="../../../images/reverse/beginner/puzzle_2.webp"
                              alt="Guides"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="first">Stage 3-15</td>
                          <td>
                            George the Oak is already 800 years old. Which
                            animals in the tree hole listened to that song with
                            you?
                          </td>
                          <td>Red squirrels and woodpeckers</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Chapter 4</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered responsive className="guide-table">
                      <thead>
                        <tr>
                          <th>Stage</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="first">Stage 4-6</td>
                          <td>
                            <StaticImage
                              src="../../../images/reverse/beginner/puzzle_3.webp"
                              alt="Guides"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="first">Stage 4-10</td>
                          <td>
                            <p>
                              Intuitive. Move the bar until the pieces of paper
                              take the shape of the boat.
                            </p>
                            <StaticImage
                              src="../../../images/reverse/beginner/puzzle_4.webp"
                              alt="Guides"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <h5>Beginning of the Tale</h5>
              <p>
                “Beginning of the Tale” refers to the Beginner Missions.
                Following these missions, you will learn the basics of the game
                and its flow, as well as earn many rewards. They are very
                intuitive in nature, and have shortcuts to take you directly to
                where you need to go. Among these missions, it can be
                highlighted:
              </p>
              <ul>
                <li>
                  You earn the resources back by leveling and then promoting
                  four of the free characters to i1. (Sonetto, APPLe, Leilani
                  and Eagle)
                </li>
                <li>
                  Completing Act 3 gives you <strong>“The Footloose”</strong>, a
                  5✦ Psychube that is useful in general to any Damage Dealer.
                </li>
              </ul>
              <div className="psychube-container">
                <Reverse1999Psychube slug="the-footloose" mode="icon" />
              </div>
              <ul>
                <li>
                  Completing Act 5 gives you{' '}
                  <strong>“Laughter and Laughter”</strong>, a 5✦ Psychube that
                  is useful in general to any Healer.
                </li>
              </ul>
              <div className="psychube-container">
                <Reverse1999Psychube slug="laughter-and-laughter" mode="icon" />
              </div>
              <ul>
                <li>
                  Completing Act 6 gives you{' '}
                  <strong>Sonetto’s “Parade Anthem” skin</strong>.
                </li>
                <li>
                  Your progress in “Beginning of the Tale” isn’t tied to the
                  progress of the main story and its rewards.
                </li>
              </ul>
              <h5>Wilderness</h5>
              <p>
                Once you unlock Wilderness (Chapter 2) try to place as many
                islands and buildings as you can as soon as possible. You can
                always change the design later for no charge, and it will be a
                good source of passive income + character bond.
              </p>
              <h5>Other General Tips</h5>
              <ul>
                <li>
                  At the fifth day of login, you will get a consumable item
                  called <strong>“Carrierwaver Carrier”</strong>, that allows
                  you to get a <u>6-star Psychube of your choice</u>. It’s
                  advised to pick one that will suit your main Damage Dealer the
                  best.
                </li>
                <li>
                  Early on, healers won’t be really needed, and you won’t have
                  to worry too much about team synergy. Pretty much any
                  character will be good enough to simply steamroll the stages.
                  However, the more the game progresses, the more important
                  healers and survival mechanics become.
                </li>
                <li>
                  The game may seem generous in materials early on because of
                  the beginner missions, but it actually takes a while to raise
                  a character. For this reason, try to not spread yourself thin
                  and plan your priorities ahead. (More on this in the Team
                  Building section)
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Initial Characters" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_3.png"
                alt="Guides"
              />
              <h5>Free Characters</h5>
              <div className="reroll-char-info first">
                <div className="top star">
                  <p className="name">APPLe</p>
                  <p>Sub-DMG, Healer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="apple"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> Prologue
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> Since APPLe heals
                      passively, try to save his skill “Equal Amount of Light”
                      and letting it merge as much as possible to have a decent
                      source of healing in a pinch. APPLe’s biggest disadvantage
                      in comparison to other healers, is the fact that he only
                      heals one ally per skill/ultimate use, and always the one
                      with the lowest HP. To remediate that, give him either an
                      additional source of healing such as “Her Second Life” (6✦
                      Psychube) or a Psychube like “Laughter and Laughter” (5✦)
                      to amp up his base healing done and help him stay alive.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Can fit well in any early game
                      team. Pairs well with “AP greedy” characters.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Maybe. As discussed
                      above, APPLe isn’t as efficient as a dedicated healer. He
                      does, however, fill a Mixed Role niche, acting as much as
                      a DPS as he does as a Healer, since almost all his healing
                      comes from his attacks. The biggest reason to invest in
                      him would be, however, his passive healing. APPLe is an AP
                      generous character, which can be extremely valuable and
                      give your main Damage Dealer or other supports more room
                      to act.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top mineral">
                  <p className="name">Sonetto</p>
                  <p>Damage Dealer, Support</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="sonetto"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> Prologue, story
                      progression (NOT available in gacha)
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> If you’re using Sonetto
                      merely to attack, there isn’t any difference in damage
                      between “Commandment V”’s level 1 and 2. If you do have a
                      copy of this card already upgraded to level 2 or a chance
                      to upgrade it, try to save it to disarm an enemy who is
                      about to attack. Most of the time, Sonetto will be
                      focusing on buffing duty, which works well with her own
                      ultimate, as it gets a bonus on DMG and Penetration Rate
                      if Sonetto is under a positive status.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Sonetto works in almost any
                      team, but she’s a notable enabler for characters whose
                      skills need the caster to be under a positive status to
                      enjoy a bonus, or those who need the enemy to be under a
                      negative status to enjoy a bonus.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Yes. Sonetto is one of
                      the most versatile characters in the game, one of the best
                      buffers, can reliably apply crowd control (Disarm) and
                      deals good damage herself. To make all that even better,
                      you can upgrade her Portray just from advancing the story,
                      and since she’s a 5✦, you can opt to promote her to i3 and
                      bring her to Late Game. Tl;dr, a flex character to almost
                      any situation.
                    </p>
                    <p>
                      <strong>Notes:</strong> Sonetto loses most of her utility
                      when it comes to boss stages. Most bosses are immune to
                      crowd control, and usually a strong single-target ultimate
                      is more useful in situations like this than AoE ultimates.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top beast">
                  <p className="name">Leilani</p>
                  <p>Damage Dealer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="leilani"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> After Stage 1-6, check one
                      of the Traces in the map
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> Leilani has a very unique
                      mechanic that gives Moxie to allies when she enters battle
                      (Passive from Insight 1) and everytime she uses her
                      Ultimate. For this reason, it is recommended to get her at
                      least to i1 L1 as soon as possible. When it comes to
                      gameplay, one of her skills, “A Greeting!” deal bonus
                      damage when the enemy is under a Control or Negative
                      status, so try to pair her with a debuffer. Her second
                      skill, “Check Carefully”, deals more damage if the enemy
                      is below 50% HP, so that should most of the time be
                      selected as the last attack of the round. Another way to
                      use Leilani, since her damage is average at best, is to
                      spam her cards early so she can use her ultimate right
                      away and give the team a headstart.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Teams with a debuffer, and
                      teams where one or more of the characters are reliant on
                      their ultimates.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Maybe. Due to being a
                      3✦ unit, Leilani’s damage isn’t impressive and doesn’t
                      scale well into the late game. Since you get the resources
                      back for raising her to insight 1, it may still be worth
                      leveling her to fill a need for Beast DMG early on, or for
                      her Moxie mechanic. If you wish to use her for sacrifice
                      strats, that’s enough. Another option is getting her to
                      max level, which is very cheap as well since she’s a low
                      rarity unit, and setting her as a Substitute. That way, if
                      someone in the team dies, Leilani enters and automatically
                      gives 1 Moxie to all allies and 2 to herself, facilitating
                      a comeback. Also, since one of her skills is best suited
                      for enemies below 50% HP, she tends to work best in
                      situations like this.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top plant">
                  <p className="name">Eagle</p>
                  <p>Damage Dealer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="eagle"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> After Stage 1-12, check
                      one of the Traces in the map
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> Much like Leilani, Eagle
                      benefits for a team with a debuffer to enjoy the bonuses
                      of her first skill, “Old Pocket Watch”. Eagle is a very
                      easy and straightforward character to play. She’s a
                      Crit-focused character, so make sure to give her critical
                      psychubes and stats.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Teams with debuffers,
                      crit-oriented teams, teams with Defense-type characters to
                      help keep her alive.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Yes. Eagle is very
                      cheap due to being a low rarity character, but her kit is
                      extremely solid and offers her the tools to be one of the
                      strongest Plant Damage Dealers in the game despite of
                      that. Once she enters combat, her passive will inflict
                      “Sense Weakness” on the enemy with the highest attack,
                      lowering its Reality DEF and Crit DEF by 20%. This means
                      that Eagle’s presence alone already helps the team,
                      especially when dealing with elite enemies. She also has a
                      very high base crit, passives that increase her ult’s crit
                      rate, and the excess crit rate gets turned into crit
                      damage, allowing Eagle to reach numbers of damage that
                      aren’t possible for other low rarity characters.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top beast">
                  <p className="name">Dikke</p>
                  <p>Sub-DMG, Healer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="dikke"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> 8th day of login
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> Dikke can be considered a
                      “mixed role” unit, as she balances healing and damage in
                      equal measures. Her healing heals all teammates, and is
                      stronger is the target is below 50% HP. Taking that into
                      consideration, try to keep her healing cards saved and let
                      them merge naturally as much as possible to have a burst
                      healing ready for the right moment. Outside that, focus on
                      attacking. Dikke can deal decent damage and should be
                      built full on ATK (her healing scales based on her ATK as
                      well). When her ult is ready, if she has any debuff on
                      her, use her ult first to not hinder the performance of
                      her healing or subsequent attacks.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Flex character, can fill in
                      for most teams. Not recommended if your team needs a
                      dedicated healer or specific utility, as utility is the
                      one thing Dikke lacks.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Yes. Dikke is very
                      worthy of investment, being a flexible unit who works well
                      on providing both damage and healing. Furthermore, her ult
                      cleanses herself of all debuffs, helping keep herself
                      alive and her output decent on both fronts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top star">
                  <p className="name">Matilda</p>
                  <p>Damage Dealer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="matilda"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>How to obtain:</strong> Pre-Registration Rewards
                      (NOT available in gacha)
                    </p>
                    <p>
                      <strong>Gameplay tips:</strong> Matilda is a crit-oriented
                      character, but her kit doesn’t work much in her favor. She
                      needs to be constantly on a buffed state and have her
                      skill “Ingenious Etude” on level 2 or above to use as an
                      opener in order to make the best use of her potential.
                    </p>
                    <p>
                      <strong>Synergies:</strong> Buffers (Sonetto being a prime
                      example), anyone who can lower the enemy’s critical def or
                      critical resist.
                    </p>
                    <p>
                      <strong>Should you invest?</strong> Probably not. Unless
                      you really like Matilda, the self-entitled “best student”
                      doesn’t have much going on in her favor even inside her
                      own niche. At i3 she only enjoys a critical technique
                      score of 356 despite being a 5✦, meanwhile, Eagle at i2
                      and being a 4✦ has a critical technique score of 445.
                      Matilda can be a decent enough Damage Dealer in the early
                      game, but the farther in the game, the more her kit plays
                      against her. Her passives don’t really add anything
                      noticeable, her multipliers are on the lower side, and she
                      needs too much set up and a team built around her to do
                      anything at all.
                    </p>
                    <p>
                      <strong>Notes:</strong> You will only receive a single
                      copy of Matilda as pre-registration reward. She’s NOT in
                      the gacha and as of now, there isn’t any way to get copies
                      of her to upgrade her Portray, making her case even more
                      difficult to defend.
                    </p>
                  </div>
                </div>
              </div>
              <h5>Starter 6-star</h5>
              <p>(From Beginner Banner, “First Drop of Rain”)</p>
              <div className="reroll-char-info">
                <div className="top star">
                  <p className="name">Regulus</p>
                  <p>Damage Dealer, Support</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="regulus"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>Gameplay tips:</strong> Get Regulus to i1 as soon
                      as possible. Her passive, “Restless Heart” gives her +50%
                      crit rate when she doesn’t act for one turn, and any
                      excess of crit rate gets turned into crit damage. That
                      means you have a downtime of one turn to manage Regulus
                      card and prepare for a burst window, or focus on the other
                      teammates. Mastering her uptime is the key for success, as
                      big utilities (reducing Moxie from enemies) relies on
                      having her skill “Challenge for the Eyes” being at least
                      at level 2 and landing a critical hit.
                    </p>
                    <p>
                      <strong>How much to invest?</strong> Regulus only needs i1
                      to unlock her main tool, “Restless Heart”. She benefits
                      heavily from Psychubes, but doesn’t need as much of a team
                      comp around crit to work as other crit-oriented
                      characters. However, a critical-focused team is still
                      welcome, since any excess in crit. rate gets added to
                      crit. damage, thus easily giving her a boost on damage.
                    </p>
                    <p className="no-border">
                      <strong>Psychubes:</strong>
                    </p>
                    <div className="psychube-container tips-psycho">
                      <Reverse1999Psychube
                        slug="thunderous-applause"
                        mode="icon"
                      />
                      <Reverse1999Psychube slug="tomorrow-also" mode="icon" />
                      <Reverse1999Psychube slug="hopscotch" mode="icon" />
                      <Reverse1999Psychube slug="brave-new-world" mode="icon" />
                    </div>
                    <p>
                      <strong>Synergies:</strong> Can work well alongside AP
                      greedy teammates since she's AP generous. If she's the
                      main Damage Dealer, try to bring allies who can buff crit
                      or inflict crit-related debuffs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top mineral">
                  <p className="name">Eternity</p>
                  <p>Damage Dealer, Survival</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="eternity"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>Gameplay tips:</strong> Much like Regulus, getting
                      Eternity at least to i1 is essential. She gains immunity
                      to [Bleed], [Corrode] and [Poison] statuses, and every
                      round she gains a stack of her passive, [Blood of
                      Longevity], stacking up to +15% DMG dealt and +15% DMG
                      Heal. That greatly helps with her survivability, but at
                      this point, it isn’t enough. Since Eternity sacrifices 10%
                      of her HP every time she casts a skill, pair her with a
                      healer or a shielder to help keep her alive.
                    </p>
                    <p>
                      <strong>How much to invest?</strong> I1 is enough to have
                      her functional, but it’s only at I3, when she gains great
                      upgrades to her passives, that Eternity truly shines.
                    </p>
                    <p className="no-border">
                      <strong>Psychubes:</strong>
                    </p>
                    <div className="psychube-container tips-psycho">
                      <Reverse1999Psychube slug="hopscotch" mode="icon" />
                      <Reverse1999Psychube
                        slug="blasphemer-of-night"
                        mode="icon"
                      />
                      <Reverse1999Psychube
                        slug="thunderous-applause"
                        mode="icon"
                      />
                    </div>
                    <p>
                      <strong>Synergies:</strong> Healers and shielders in
                      general. When going duo, bring someone who can offer extra
                      utility through buffs, moxie mechanics or counter. (Too
                      many options of all rarities to list here)
                    </p>
                  </div>
                </div>
              </div>
              <div className="reroll-char-info">
                <div className="top star">
                  <p className="name">Lilya</p>
                  <p>Damage Dealer</p>
                </div>
                <div className="middle">
                  <div className="character">
                    <Card className="avatar-card">
                      <Reverse1999Character
                        slug="lilya"
                        mode="card"
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="information">
                    <p>
                      <strong>Gameplay tips:</strong> Lilya is a very
                      straightforward character to play, but take note that she
                      doesn’t convert excess crit rate into crit DMG.
                    </p>
                    <p>
                      <strong>How much to invest?</strong> Lilya starts to shine
                      at i2, when her base stats and Resonance level begin to
                      reach higher levels. However, her i3 passive is essential
                      to make her reach her potential. She also needs a team
                      built around her.
                    </p>
                    <p className="no-border">
                      <strong>Psychubes:</strong>
                    </p>
                    <div className="psychube-container tips-psycho">
                      <Reverse1999Psychube
                        slug="thunderous-applause"
                        mode="icon"
                      />
                      <Reverse1999Psychube
                        slug="an-afternoon-nap"
                        mode="icon"
                      />
                      <Reverse1999Psychube slug="hopscotch" mode="icon" />
                    </div>
                    <p>
                      <strong>Synergies:</strong> Crit-oriented supports and AP
                      generous allies, so Lilya can spam her cards and charge
                      her ultimate as often as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Team Building 101" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_4.png"
                alt="Guides"
              />
              <p>
                For now we are only going to dip our toes in Team Building. This
                is a Beginner’s Guide after all.{' '}
              </p>
              <h5>How many units to raise at first</h5>
              <p>
                Between 4 to 6 units early on. Depends on the amount of high
                rarities you're trying to build, as the 6✦ in particular are
                considerably more expensive. I'd advise you to pick only a 6✦ to
                build at first, then the others should be from varying elements
                and roles. Once you have a solid main team in this fashion, you
                can slowly branch out to have mono-type teams. Synergy is vital
                in this game, so when in doubt of who to raise or invest in,
                check synergy with who you already have.
              </p>
              <h5>Team structure</h5>
              <p>
                Team composition can be very flexible and varied since most
                characters can do more than one thing, but there’s a generic
                guideline you can follow. There are three main roles:
              </p>
              <ol>
                <li>
                  <strong>Damage Dealer</strong>: Characters focused on dealing
                  as much damage as possible. They may be great at that job, but
                  some of them don’t offer any utility at all.
                </li>
                <li>
                  <strong>Support</strong>: Characters whose focus is on their
                  ability to enable their teammates (especially the main Damage
                  Dealer). They often can act as a Sub-DMG too, but the main
                  reason to bring them is for their buffs, debuffs or control
                  skills.
                </li>
                <li>
                  <strong>Survival</strong>: Arcanists focused on keeping their
                  teammates alive. They’re the healers, shielders and tanks.
                </li>
              </ol>
              <p>
                There are some special cases where a character has a perfect
                balance between two of these roles, in which case we are going
                to refer to them as “Mixed Role”. Being Flex unit, they can
                freely take part in many different teams to fill a niche, or are
                ideal characters to have in your Substitute slot.
              </p>
              <h5>How to compose a team:</h5>
              <p>
                First, choose your main Damage Dealer. Then, see what kind of
                support they need to bring forth the most of their potential.
                When in doubt, you can find that out by checking the full
                character reviews for suggestions on synergy. Last but not
                least, the team will need either a second Support or a Survival
                character. Damage Dealers tend to die easily, so towards the
                endgame, making sure they stay alive becomes more and more
                important. Last but not least, the Substitute slot. As commented
                above, it can be a flex character or even a second Damage
                Dealer, in case the first one dies and you’re close to clearing
                the stage.
              </p>
              <p>
                If you’re running low on materials, most low rarity characters
                are focused on utility, and can fill a specific niche. Since
                they’re cheap to raise, you can always consider them to fill the
                role you need in the meantime.
              </p>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Account Progression" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_5.webp"
                alt="Guides"
              />
              <h5>Routine</h5>
              <ul>
                <li>
                  Dailies usually take only a couple of minutes. Interact with
                  the character at the main menu, gather resources from
                  Wilderness, run Pneuma Analysis, and spend your Activity
                  (stamina) towards whatever you’re working on at the moment.
                  Since the game has a replay mode that can give x4 rewards at
                  once, this gets done quickly.
                </li>
                <li>
                  Do the Psychube stages (Pneuma Analysis) twice every day, they
                  cost you nothing and the resources are important.
                </li>
                <li>
                  Once you unlock Artificial Somnambulism (early Chapter 3), try
                  it. It's a huge boost on Sharpodonty, general resources
                  through the Oneiric Shop and Clear Drops for gacha. Try to
                  finish all stages from "Surface" even if you don't get perfect
                  stars so you can unlock "Limbo". "Surface" gives you a passive
                  income every week, and Limbo can give you up to 600 Clear
                  drops (gacha income) and Oneiric Shop currency every two
                  weeks.
                </li>
                <li>
                  Try to plan out ahead since resources are scarce, but don't
                  stress too much about it since side-events give plenty of
                  resources and mats.
                </li>
              </ul>
              <h5>Extra tips about Psychubes</h5>
              <p>
                Since you can only bring up to four characters per stage, you
                likely won’t need a big amount of them. Low rarity Psychubes are
                only fodder to level up the high rarity ones; As long as you
                keep doing the Pneuma Analysis stages daily, you should be able
                to save currency for Psychubes in no time. Once you have your
                main team covered, use this currency to buy copies of Psychubes.
                Dupes (or the “Gluttony” Psychube) can be used to increase the
                level of the Psychube’s passive, making it a lot more effective
                or powerful, depending on its nature.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Spending tips" />
              <StaticImage
                src="../../../images/reverse/beginner/banner_6.png"
                alt="Guides"
              />
              <p>
                If you plan on spending money in Reverse:1999, below you can
                check a list of recommended purchase priorities based on
                different needs.{' '}
                <strong>
                  The prices shown in this section come from the CN version of
                  the game
                </strong>{' '}
                - we will update the guide with official prices once the game
                releases.
              </p>
              <p>
                <strong>Recommended spending order for Summons:</strong>
              </p>
              <p>
                Roaring Month (Monthly Card) &gt; Rookie Shopping Day &gt;
                Initial Summon Pack &gt; Offer of the Month &gt; Rookie Offer =
                Crystal Drops (with first charge bonus) &gt; Roar Jukebox (BP)
                &gt; Crystal Drops (without first charge bonus)
              </p>
              <p>
                <strong>
                  Recommended spending order for Materials/Raising characters:
                </strong>
              </p>
              <p>
                Roaring Month &gt; Roar Jukebox &gt; Rookie Shopping Day &gt;
                Rookie Offer
              </p>
              <p>
                <strong>Balanced spending order:</strong>
              </p>
              <p>
                Roaring Month &gt; Roar Jukebox &gt; Rookie Shopping Day &gt;
                Initial Summon Pack &gt; Offer of the Month &gt; Rookie Offer
                &gt; Crystal Drops (with first charge bonus) &gt; Crystal Drops
                (without first charge bonus)
              </p>
              <Table striped bordered responsive className="table-shop">
                <thead>
                  <tr>
                    <th>Offer image</th>
                    <th>Name</th>
                    <th>Content</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <StaticImage
                        src="../../../images/reverse/beginner/shop_1.webp"
                        alt="Guides"
                      />
                    </td>
                    <td>
                      <strong>ROARING MONTH</strong>
                    </td>
                    <td>
                      <ul>
                        <li>300 Crystal Drop</li>
                        <li>2700 Clear Drop</li>
                        <li>1800 Activity (Stamina)</li>
                      </ul>
                    </td>
                    <td>
                      <strong>¥30 (~$4)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <StaticImage
                        src="../../../images/reverse/beginner/shop_2.webp"
                        alt="Guides"
                      />
                    </td>
                    <td>
                      <strong>ROOKIE SHOPPING DAY</strong>
                    </td>
                    <td>
                      <ul>
                        <li>Bunny Bunny (4✦ Character)</li>
                        <li>Unilog x1</li>
                        <li>Dust x10000</li>
                        <li>Sharpodonty x10000</li>
                      </ul>
                    </td>
                    <td>
                      <strong>¥6 (~$1)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <StaticImage
                        src="../../../images/reverse/beginner/shop_4.webp"
                        alt="Guides"
                      />
                    </td>
                    <td>
                      <strong>ROOKIE OFFER</strong>
                    </td>
                    <td>
                      <ul>
                        <li>Clear Drop x1360</li>
                        <li>Sharpodonty x35000</li>
                        <li>Dust x35000</li>
                        <li>Fine Insight Casket x6</li>
                        <li>Simple Insight Casket x6</li>
                        <li>Bottle of Pages x6</li>
                      </ul>
                    </td>
                    <td>
                      <strong>¥68 (~$10)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <StaticImage
                        src="../../../images/reverse/beginner/shop_3.webp"
                        alt="Guides"
                      />
                    </td>
                    <td>
                      <strong>INITIAL SUMMON PACK</strong>
                    </td>
                    <td>
                      <ul>
                        <li>Unilog x20</li>
                      </ul>
                    </td>
                    <td>
                      <strong>¥128 (~$18)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <StaticImage
                        src="../../../images/reverse/beginner/shop_5.webp"
                        alt="Guides"
                      />
                    </td>
                    <td>
                      <strong>OFFER OF THE MONTH</strong>
                    </td>
                    <td>
                      <ul>
                        <li>Clear Drops x1800</li>
                        <li>Unilog x10</li>
                      </ul>
                    </td>
                    <td>
                      <strong>¥168 (~$23)</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Battle Pass</h5>
              <StaticImage
                src="../../../images/reverse/beginner/shop_7.webp"
                alt="Guides"
              />
              <p>
                The Deluxe Edition of the Battle Pass costs{' '}
                <strong>¥68 (~$10)</strong> and the Collector Edition costs{' '}
                <strong>¥98 (~$14)</strong>.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Common Terms</a>
                </li>
                <li>
                  <a href="#section-2">First steps</a>
                </li>
                <li>
                  <a href="#section-3">Initial Characters</a>
                </li>
                <li>
                  <a href="#section-4">Team Building 101</a>
                </li>
                <li>
                  <a href="#section-5">Account Progression</a>
                </li>
                <li>
                  <a href="#section-6">Spending tips</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesBeginner;

export const Head: React.FC = () => (
  <Seo
    title="Beginner Guide | Reverse: 1999 | Prydwen Institute"
    description="A beginner guide to Reverse: 1999 that will help you start strong in
    the game."
    game="reverse"
  />
);
